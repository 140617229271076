.main-footer {
    margin-top: auto;
    position: relative;
    padding-top: 50rem;
    padding-bottom: 50rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: $c-accent;
    overflow: hidden;
    z-index: 11;

    &__content {
        position: relative;
        z-index: 3;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 22px;
        border-bottom: 1px solid #ffffff4a;
        @include r($md) {
            flex-wrap: wrap;
        }
    }

    &__logo {
        &-img {
            height: 54px;
        }
    }

    .other-magazine {
        display: flex;
        align-items: center;
        height: 66px;
        img {
            height: 38px;
            @include r($md) {
                margin-top: 10px;
            }
        }
        color: #fff;
        span {
            display: flex;
            align-items: center;
            font-size: 18px;
            height: 100%;
            border-right: 1px solid #ffffff4a;
            padding-right: 2em;
            margin-right: 2em;
            @include tr;
            @include r($md) {
                font-size: 16px;
                display: block;
                width: 100%;
                border-right: none;
                // border-bottom: 1px solid #ffffff4a;
                padding-bottom: 15px;
                padding-top: 15px;
            }
        }
        &:hover {
            span {
                color: $c-color2;
            }
        }
        @include r($md) {
            margin-top: 30rem;
            width: 100%;
            height: auto;
            flex-wrap: wrap;
        }
    }

    &__links {
        padding-top: 60rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include r($xl) {
            flex-wrap: wrap;
            padding-top: 40rem;
        }
    }

    &__newsletter {
        width: 500px;
        @include r($xxl) {
            width: 400px;
        }
        @include r($xl) {
            order: 2;
            width: 100%;
        }
    }

    &__menus {
        width: calc(100% - 650px);
        padding-left: 5%;
        @include r($xxl) {
            width: calc(100% - 420px);
        }
        @include r($xl) {
            order: 1;
            width: 100%;
            padding-left: 0;
            margin-bottom: 35rem;
        }
    }

    .footer-menu {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: #fff;
        list-style-type: none;
        &__item {
            margin-bottom: 0.75em;
        }
        &__link {
            font-size: 18px;
            padding-top: 0.1em;
            @include tr;
            &:hover {
                color: $c-color2;
            }
            @include r($xxl) {
                font-size: 16px;
            }
            @include r($md) {
                font-size: 17px;
                padding-top: 0.15em;
                padding-bottom: 0.15em;
                display: block;
            }
            @include r($sm) {
                font-size: 16px;
            }
        }
        @include r($md) {
            display: block;
        }
    }

    &__bottom {
        color: #fff;
        margin-top: 60rem;
        padding-bottom: 30rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        @include r($xl) {
            margin-top: 30rem;
        }
        @include r($md) {
            flex-wrap: wrap;
        }
    }

    &__copy {
        font-size: 13px;
        @include r($md) {
            order: 2;
            width: 100%;
            margin-top: 1em;
        }
    }

    &__social {
        font-size: 14px;
        display: flex;
        align-items: center;

        .social-icon {
            font-size: 1em;
            display: flex;
            align-items: center;
            background-color: unset;
            width: auto;
            @include tr;
            &:hover {
                opacity: 0.8;
                background-color: unset;
            }
            img {
                margin-left: 15px;
                width: 38px;
            }
        }
        @include r($md) {
            order: 1;
            width: 100%;
        }
    }

    &__tractor {
        position: relative;
        z-index: 2;
        bottom: 0;
        width: 180px;
        height: 95px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -49px;
        --t-ccolor: #abaf77;
        .tractor-image {
            width: 100%;
            height: 100%;
        }
        .wheel-1 {
            position: absolute;
            display: block;
            width: 17px;
            height: 17px;
            bottom: 18px;
            left: 39px;
            border-radius: 50%;
            border: 5px solid var(--t-ccolor);
            animation: spin 2s linear infinite;
            &:before,
            &:after {
                content: "";
                display: block;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: $c-accent;
                margin-top: -2px;
            }
            &:after {
                margin-bottom: -2px;
            }
        }
        .wheel-2 {
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            bottom: 14px;
            left: 113px;
            border-radius: 50%;
            border: 3px solid var(--t-ccolor);
            animation: spin 2s linear infinite;
            &:before,
            &:after {
                content: "";
                display: block;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: $c-accent;
                margin-top: -2px;
            }
            &:after {
                margin-bottom: -2px;
            }
        }
        @include r($md) {
            display: none;
        }
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 14000px; /* The image width times 3 */
        height: 340px;
        background-image: url("../img/_dist/wave-2.svg");
        background-size: 14000px 340px;
        background-repeat: repeat-x;
        animation: slide 180s linear infinite;
        z-index: 1;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 45px;
        width: 14000px;
        background-image: url("../img/_dist/grass.svg");
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 1900px 30px;
        animation: slide2 180s linear infinite;
        z-index: 2;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes slide {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-11000px, 0, 0); /* The image width */
    }
}

@keyframes slide2 {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-11100px, 0, 0); /* The image width */
    }
}

.newsletter {
    color: #fff;
    &__form {
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 5px;
    }

    &__button {
        background-color: $c-color2;
        color: #fff;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.72em 0.95em;
        border-radius: 10px;
        flex-shrink: 0;
        @include tr;
        &:hover {
            background-color: $c-accent;
        }
        &__text {
            font-size: 16px;
            margin-left: 10px;
            display: block;
            @include r($md) {
                font-size: 14px;
            }
        }
        .sprite-icon {
            font-size: 20px;
            @include r($md) {
                font-size: 14px;
            }
        }
    }

    &__input {
        border: none;
        padding: 0.72em 0.5em;
        outline: none;
        width: 100%;
        &::placeholder {
            color: $c-accent;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            color: $c-accent;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.35;
        @include r($xxl) {
            font-size: 22px;
            br {
                display: none;
            }
        }
        @include r($xl) {
            font-size: 20px;
        }
    }

    &__text {
        font-size: 14px;
        margin-top: 1em;
        margin-bottom: 1em;
    }
}

.footer-mill {
    position: absolute;
    right: 1%;
    bottom: 0;
    z-index: 3;
    @include r(1700px) {
        img {
            max-height: 120px;
        }
    }
    @include r($md) {
        display: none;
    }
}
