::placeholder {
    color: $c-accent;
    line-height: normal;
}
.input {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 17rem 20rem;
    border: 1px solid $c-border;
    border-radius: 4px;
    background: $c-gray;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.5;
    text-overflow: ellipsis;
    color: $c-text;
    caret-color: currentColor;
    box-shadow: none;
    transition: $trs-dur;
    &:focus {
        outline: 0;
        border-color: $c-accent;
    } // hide placeholder on focus
    &:focus::placeholder {
        opacity: 0.6;
        transition: opacity 0.3s ease;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: currentColor;
        caret-color: currentColor;
    }
    &_style_line {
        border: 2px solid $c-border;
        border-width: 0 0 2px;
        padding: 8rem 0 6rem;
    }
    &.error {
        border-color: $c-red;
    }
}
.textarea {
    display: block;
    resize: none;
    width: 100%;
    height: 120rem;
    max-width: 100%;
    padding: 17rem 20rem;
    border: 1px solid $c-border;
    border-radius: 4px;
    background: $c-gray;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.5;
    text-overflow: ellipsis;
    color: $c-text;
    caret-color: currentColor;
    box-shadow: none;
    transition: $trs-dur;
    &.error {
        border-color: $c-red;
    }
    &:focus {
        outline: 0;
        border-color: $c-accent;
    }
    // hide placeholder on focus
    &:focus::placeholder {
        opacity: 0.6;
        transition: opacity 0.3s ease;
    }
}
.select-wrap {
    display: block;
    // overflow: hidden;
    // padding: 0;
    // margin: 0;
    cursor: default;
    position: relative;
    color: $c-text;
    z-index: 1;
}
.select {
    display: block;
    width: 100%;
    font-size: 16rem;
    line-height: 1.5;
    padding: 17rem 70rem 17rem 20rem;
    box-shadow: none;
    appearance: none;
    background-color: $c-gray;
    background-image: url(../img/_dist/graphic/arrow-down.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 50%;
    border: 1px solid $c-border;
    border-radius: 4px;
    cursor: pointer;
    color: $c-text;
    text-overflow: ellipsis;
    transition: 0.3s;
    &[disabled] {
        background-color: $c-disabled;
        border-color: $c-disabled-text;
        color: $c-disabled-text;
        pointer-events: none;
        cursor: default;
    }
    &.empty {
        color: $c-gray;
    }
    &.error {
        border-color: $c-red;
    }
    option {
        color: $c-text;
    }
    &:focus {
        outline: 0;
        border-color: $c-accent;
        background-image: url(../img/_dist/graphic/arrow-down.svg);
    }
    &::-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
        outline: 0;
    }
    &::-ms-expand {
        display: none;
    }
    &:disabled {
        background-color: $c-gray;
    }
    &_size_small {
        background-position: calc(100% - 14px) 50%;
        padding-right: 50px;
        padding-top: 11rem;
        padding-bottom: 11rem;
    }
    &_style_line {
        border-width: 1px;
        border: 2px solid $c-border;
        border-width: 0 0 2px;
        padding: 8rem 50rem 6rem 0;
        background-position: calc(100% - 11rem) 50%;
    }
}
.input-wrap {
    position: relative;
}
.fields-set {
    --bs-gutter-x: 30rem;
    --bs-gutter-y: 12rem;
    text-align: left;
    margin-bottom: 1.5em;
    @include r($md) {
        --bs-gutter-y: 6px;
        margin-bottom: 0.5em;
    }
    &__submit {
        margin-top: 20rem;
        width: 100%;
    }
}
.form-col {
    margin-bottom: 42rem;
}
.field {
    display: block;
    position: relative;
    &__label {
        display: block;
        position: relative;
    }
    &__input-wrap {
        display: block;
        position: relative;
        z-index: 1;
    }
    &__error {
        padding-top: 0.5em;
    }
    &__icon {
        position: absolute;
        font-size: 20rem;
        top: 20rem;
        left: 17rem;
        color: inherit;
        & + .form__element {
            padding-left: 50rem;
        }
    }
    &__text {
        display: block;
    }
    @include r($md) {
        margin-top: 0.75em;
    }
}

.checkbox,
.radio {
    cursor: pointer;
    position: relative;
    color: $c-text;
    font-size: 16px;
    transition: 0.3s;
    line-height: 1.4;
    user-select: none;
    @include r($md) {
        font-size: 13px;
    }
    @include r($sm) {
        font-size: 12px;
    }
    &__input {
        top: 0.25em;
        left: 0.25em;
        opacity: 0;
        position: absolute;
        &:checked + .checkbox__content,
        &:checked + .radio__content {
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }
        &:checked + .checkbox__content {
            &:before {
                background-color: $c-accent;
                border-color: $c-accent;
            }
        }
    }
    &__content {
        padding-left: 2em;
        min-height: 1.5em;
        display: inline-flex;
        position: relative;
        align-items: center;
        padding-top: 0.1em;

        a {
            color: $c-accent;
            text-decoration: underline;
            &:hover,
            &:focus {
                color: $c-accent-hover;
            }
        }
        &::before {
            content: "";
            position: absolute;
            left: 0.15em;
            top: 0.15em;
            width: 1.25em;
            height: 1.25em;
            border-radius: 4px;
            border: 1px solid $c-accent;
            background-color: #fff;
            background-position: 50%;
            background-size: auto 50%;
            transition: 0.3s;
        }
        &::after {
            content: "";
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'><g><path fill='%23fff' d='M.24 5.101l1.194-1.533 3.19 2.63L9.865.45l1.452 1.302-6.49 7.169z'/></g></svg>");
            background-size: 1em 1em;
            background-repeat: no-repeat;
            position: absolute;
            top: 0.25em;
            left: 0.27em;
            width: 1em;
            height: 1em;
            transition: 0.3s;
            opacity: 0;
            visibility: hidden;
        }
    }
    a {
        color: inherit;
        text-decoration: underline;
        padding-left: 3px;
        padding-right: 3px;
        &:hover {
            text-decoration: none;
        }
    }
}

.radio {
    font-size: 16px;
    @include r($md) {
        font-size: 14px;
    }
    &__content {
        padding-top: 0.3em;
        &::before {
            width: 1.8em;
            height: 1.8em;
            border-radius: 50%;
        }
        &:after {
            background-image: none;
            background-color: $c-accent;
            border-radius: 50%;
            width: 1em;
            height: 1em;
            top: 0.4em;
            left: 0.4em;
        }
    }
}

input[type="file"] {
    -webkit-appearence: none;
    color: inherit;
}

input::file-selector-button {
    color: inherit;
    padding: 0.5em;
    border-radius: 3px;
    border: 1px solid #4f4f4f;
}
